$grid: (
    type: grid,
    default: default,
    generateGridClassesFromBreakpoints: true,
    columns: 12,
    column-gap: 1rem,
    row-gap: 1.5rem
);
$breakpoints: (
    desktop: 64rem,
    dividedteaser: 48em,
    history: 52em,
    history-lg: 74.3em,
    screenSmall: 767px
);
            @import "STYLE_UTILS/_base";// Font
.cmp-linklist__header--text .cmp-linklist__item--text {
  font-family: map-get($fonts, default);
}

// SVG Icon
.cmp-linklist__header--link,
.cmp-linklist__item--link {
  > .sw-icon {
    display: inline;
    height: rem(10px);
    stroke: currentColor;
    width: rem(10px);
  }
}

// Element Styles
.cmp-linklist {
  & a {
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }

  & ul {
    list-style-type: none;
    padding-left: 0;
  }
}

// Nudge for right carat
.cmp-linklist__header--icon-carat-right,
.cmp-linklist__item--icon-carat-right {
  .sw-icon {
    margin-left: 0;
    transition: transform map-get($transitions, default);
  }

  &:hover .sw-icon {
    transform: translateX(rem(8));
  }
}
